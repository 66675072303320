import React, { createContext, useContext } from 'react';
import { useLanguage } from './LanguageContext';

import Accueil_fr from "../Contenu/fr/Accueil/global.json";
import Accueil_en from "../Contenu/en/Accueil/global.json";
import Accueil_es from "../Contenu/es/Accueil/global.json";
import Accueil_cat from "../Contenu/cat/Accueil/global.json";

import Url_fr from "../Contenu/fr/Url/global.json";
import Url_en from "../Contenu/en/Url/global.json";
import Url_es from "../Contenu/es/Url/global.json";
import Url_cat from "../Contenu/cat/Url/global.json";

import Famille_es from "../Contenu/es/Famille/global.json";
import Famille_fr from "../Contenu/fr/Famille/global.json";
import Famille_en from "../Contenu/en/Famille/global.json";
import Famille_cat from "../Contenu/cat/Famille/global.json";

import Produit_es from "../Contenu/es/Produit/global.json";
import Produit_fr from "../Contenu/fr/Produit/global.json";
import Produit_en from "../Contenu/en/Produit/global.json";
import Produit_cat from "../Contenu/cat/Produit/global.json";

import GeneralFiche_es from "../Contenu/es/Fiche/general.json";
import GeneralFiche_fr from "../Contenu/fr/Fiche/general.json";
import GeneralFiche_en from "../Contenu/en/Fiche/general.json";
import GeneralFiche_cat from "../Contenu/cat/Fiche/general.json";

import Fiche_es from "../Contenu/es/Fiche/global.json";
import Fiche_fr from "../Contenu/fr/Fiche/global.json";
import Fiche_en from "../Contenu/en/Fiche/global.json";
import Fiche_cat from "../Contenu/cat/Fiche/global.json";

import blog_es from "../Contenu/es/Blog/global.json";
import blog_fr from "../Contenu/fr/Blog/global.json";
import blog_en from "../Contenu/en/Blog/global.json";
import blog_cat from "../Contenu/cat/Blog/global.json";

import contact_es from "../Contenu/es/Contact/global.json";
import contact_fr from "../Contenu/fr/Contact/global.json";
import contact_en from "../Contenu/en/Contact/global.json";
import contact_cat from "../Contenu/cat/Contact/global.json";

import cookies_es from "../Contenu/es/Cookies/global.json";
import cookies_fr from "../Contenu/fr/Cookies/global.json";
import cookies_en from "../Contenu/en/Cookies/global.json";
import cookies_cat from "../Contenu/cat/Cookies/global.json";

import mentions_es from "../Contenu/es/Mentions/global.json";
import mentions_fr from "../Contenu/fr/Mentions/global.json";
import mentions_en from "../Contenu/en/Mentions/global.json";
import mentions_cat from "../Contenu/cat/Mentions/global.json";

import confidentialite_es from "../Contenu/es/Confidentialite/global.json";
import confidentialite_fr from "../Contenu/fr/Confidentialite/global.json";
import confidentialite_en from "../Contenu/en/Confidentialite/global.json";
import confidentialite_cat from "../Contenu/cat/Confidentialite/global.json";

import devis_es from "../Contenu/es/Devis/global.json";
import devis_fr from "../Contenu/fr/Devis/global.json";
import devis_en from "../Contenu/en/Devis/global.json";
import devis_cat from "../Contenu/cat/Devis/global.json";

import nav_es from "../Contenu/es/general/nav.json";
import nav_fr from "../Contenu/fr/general/nav.json";
import nav_en from "../Contenu/en/general/nav.json";
import nav_cat from "../Contenu/cat/general/nav.json";

import societe_fr from "../Contenu/fr/Societe/global.json"
import societe_en from "../Contenu/en/Societe/global.json"
import societe_es from "../Contenu/es/Societe/global.json"
import societe_cat from "../Contenu/cat/Societe/global.json"

import footer_es from "../Contenu/es/general/footer.json";
import footer_fr from "../Contenu/fr/general/footer.json";
import footer_en from "../Contenu/en/general/footer.json";
import footer_cat from "../Contenu/cat/general/footer.json";

const TraductionContext = createContext();

export const TraductionProvider = ({ children }) => {
    const { language } = useLanguage();
    const traductions = {
        en: {
            contentAccueil: Accueil_en,
            contentUrl: Url_en,
            contentFiche: Fiche_en,
            contentGeneralFiche: GeneralFiche_en,
            contentProduit: Produit_en,
            contentFamille: Famille_en,
            contentNav: nav_en,
            contentBlog: blog_en,
            contentFooter: footer_en,
            contentContact: contact_en,
            contentCookies: cookies_en,
            contentDevis: devis_en,
            contentMentions: mentions_en,
            contentConfidentialite: confidentialite_en,
            contentSociete: societe_en
        },
        fr: {
            contentAccueil: Accueil_fr,
            contentUrl: Url_fr,
            contentFiche: Fiche_fr,
            contentGeneralFiche: GeneralFiche_fr,
            contentProduit: Produit_fr,
            contentFamille: Famille_fr,
            contentNav: nav_fr,
            contentBlog: blog_fr,
            contentFooter: footer_fr,
            contentContact: contact_fr,
            contentCookies: cookies_fr,
            contentDevis: devis_fr,
            contentMentions: mentions_fr,
            contentConfidentialite: confidentialite_fr,
            contentSociete: societe_fr
        },
        es: {
            contentAccueil: Accueil_es,
            contentUrl: Url_es,
            contentFiche: Fiche_es,
            contentGeneralFiche: GeneralFiche_es,
            contentProduit: Produit_es,
            contentFamille: Famille_es,
            contentNav: nav_es,
            contentBlog: blog_es,
            contentFooter: footer_es,
            contentContact: contact_es,
            contentCookies: cookies_es,
            contentDevis: devis_es,
            contentMentions: mentions_es,
            contentConfidentialite: confidentialite_es,
            contentSociete: societe_es
        },
        cat: {
            contentAccueil: Accueil_cat,
            contentUrl: Url_cat,
            contentFiche: Fiche_cat,
            contentGeneralFiche: GeneralFiche_cat,
            contentProduit: Produit_cat,
            contentFamille: Famille_cat,
            contentNav: nav_cat,
            contentBlog: blog_cat,
            contentFooter: footer_cat,
            contentContact: contact_cat,
            contentCookies: cookies_cat,
            contentDevis: devis_cat,
            contentMentions: mentions_cat,
            contentConfidentialite: confidentialite_cat,
            contentSociete: societe_cat
        }
    };

    const { 
        contentAccueil, 
        contentUrl, 
        contentFiche, 
        contentGeneralFiche, 
        contentProduit, 
        contentFamille, 
        contentNav, 
        contentBlog, 
        contentFooter,
        contentContact,
        contentCookies,
        contentDevis,
        contentMentions,
        contentConfidentialite,
        contentSociete
    } = traductions[language] || traductions.en;

    return (
        <TraductionContext.Provider 
            value={{ 
                contentAccueil, 
                contentUrl, 
                contentFiche, 
                contentGeneralFiche, 
                contentProduit, 
                contentFamille,
                contentNav,
                contentBlog,
                contentFooter,
                contentContact,
                contentCookies,
                contentDevis,
                contentMentions,
                contentConfidentialite,
                contentSociete
            }}>

            {children}
        </TraductionContext.Provider>
    );
};

export const useTraduction = () => useContext(TraductionContext);

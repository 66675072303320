import React, { createContext, useState, useEffect, useContext } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState("");
    const [domainName, setDomainName] = useState("");

    useEffect(() => {
        const fetchDomainName = async () => {
            try {
                const response = await fetch('/domain-name');
                const data = await response.json();
                const domainUrl = data.domain ? data.domain.split('/')[0] + '//' + data.domain.split('/')[2] : '';
                setDomainName(domainUrl);
            } catch (error) {
                console.error('Erreur lors de la récupération du nom de domaine:', error);
            }
        };

        fetchDomainName();
    }, []);

    useEffect(() => {
        const domain = domainName.replace(/^https?:\/\//, '');



        if (domain.includes('transportadores-renau.es')) {
            setLanguage('es');
        } else if (domain.includes('convoyeurs-renau.fr')) {
            setLanguage('fr'); 
        } else if (domain.includes('localhost:3000')) {
            setLanguage('es'); 
            
        } else if (domain.includes('conveyors-renau.en')) {
            setLanguage('en');
        }

        
    }, [domainName]);

    return (
        <LanguageContext.Provider value={{ language, domainName }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    return useContext(LanguageContext);
};
